<template>
  <div id="app">
    <!-- <transition name="fade">   -->
    <router-view></router-view>
    <!-- </transition>  -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  watch: {},
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
body,
html {
  margin: 0;
  background: #0a0c17;
  padding-bottom: constant(safe-area-inset-bottom); /* 适配iPhoneX */
  padding-bottom: env(safe-area-inset-bottom); /* 适配iPhoneX */
}
* {
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}

@font-face {
  font-family: "PingFangSC";
  src: url(./assets/PingFangSCRegular.ttf) format("truetype");
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.3rem;
  color: #212121;
  font-family: "PingFangSC", sans-serif;
}

.fade-leave-active,
.fade-enter-active {
  transition: 0.5s linear;
}
/* 可能为enter失效，拆分为 enter-from和enter-to */
.fade-enter {
  // opacity: 0;
  transform: translateY(100%);
}
.fade-leave-to {
  // opacity: 0;
  transform: translateY(-100%);
}
</style>
