<template>
  <div class="detail">
    <div class="btn" @click="goback">返回（{{ timeout }}）</div>
    <img
      :src="require('./home/imgs/moral_d_' + edition + '_' + index + '.png')"
      @click="goback"
    />
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
      edition: 1,
      index: 0,
      timeout: 180,
      timer: null
    };
  },
  created() {
    this.startTimer();
  },
  mounted() {
    this.edition = this.$route.query.edition;
    this.index = this.$route.query.index;
  },
  beforeDestroy() {},
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.timeout -= 1;
        if (this.timeout <= 0) {
          this.goback();
        }
      }, 1000);
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    goback() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.detail {
  height: 100%;
  margin: 0;
  img {
    width: 100%;
    height: 100%;
  }
  .btn {
    color: #4b0a05;
    background-color: #fed587;
    width: 160px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    font-size: 22px;
    border-radius: 10px;
    text-align: center;
    position: fixed;
    bottom: 30px;
    left: 30px;
  }
}
</style>
