var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('el-carousel',{attrs:{"height":_vm.windowHeight + 'px',"interval":5000,"autoplay":true,"loop":true,"indicator-position":"none"}},_vm._l((_vm.moralList),function(moral){return _c('el-carousel-item',{key:moral.edition},[_c('div',{staticClass:"moral"},[_c('div',{staticClass:"edition",style:({ top: _vm.editionTop + '%' })},[_vm._v(" 第"+_vm._s(_vm._f("converToUpper")(moral.edition))+"届 ")]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"firstList",style:({
              'grid-template-columns':
                'repeat(' +
                moral.firstRowCol +
                ',' +
                100 / moral.firstRowCol +
                '%)'
            })},_vm._l((moral.firstList),function(item){return _c('div',{key:item.index,staticClass:"moral-item"},[_c('div',{staticClass:"moral-content",style:({
                  width:
                    _vm.windowWidth /
                      (moral.firstRowCol < 6 ? 6 : moral.firstRowCol + 1) +
                    'px'
                }),on:{"click":function($event){return _vm.go(moral, item)}}},[_c('img',{staticClass:"moral-img",attrs:{"src":require('./imgs/moral_p_' +
                      moral.edition +
                      '_' +
                      item.index +
                      '.jpg')}}),_c('div',{staticClass:"moral-name",class:{
                    'moral-fulldead': item.isFullDead,
                    'moral-full': item.isFull
                  }},[_c('span',{class:{
                      'moral-dead':
                        item.isDead && !item.isFullDead && !item.isFull,
                      'moral-moretext':
                        item.name.length > 10 &&
                        !item.isFullDead &&
                        !item.isFull
                    }},[_vm._v(_vm._s(item.name))]),(item.name2)?_c('span',{class:{ 'moral-dead moral-dead2': item.isDead2 }},[_vm._v(_vm._s(item.name2))]):_vm._e(),(item.name3)?_c('span',{class:{ 'moral-dead moral-dead3': item.isDead3 }},[_vm._v(_vm._s(item.name3))]):_vm._e()])])])}),0),_c('div',{staticClass:"secondList",style:({
              'grid-template-columns':
                'repeat(' +
                moral.secondRowCol +
                ',' +
                100 / moral.secondRowCol +
                '%)'
            })},_vm._l((moral.secondList),function(item){return _c('div',{key:item.index,staticClass:"moral-item"},[_c('div',{staticClass:"moral-content",style:({
                  width:
                    _vm.windowWidth /
                      (moral.firstRowCol < 6 ? 6 : moral.firstRowCol + 1) +
                    'px'
                }),on:{"click":function($event){return _vm.go(moral, item)}}},[_c('img',{staticClass:"moral-img",attrs:{"src":require('./imgs/moral_p_' +
                      moral.edition +
                      '_' +
                      item.index +
                      '.jpg')}}),_c('div',{staticClass:"moral-name",class:{
                    'moral-fulldead': item.isFullDead,
                    'moral-full': item.isFull
                  }},[_c('span',{class:{
                      'moral-dead':
                        item.isDead && !item.isFullDead && !item.isFull,
                      'moral-moretext':
                        item.name.length > 10 &&
                        !item.isFullDead &&
                        !item.isFull
                    }},[_vm._v(_vm._s(item.name))]),(item.name2)?_c('span',{class:{ 'moral-dead moral-dead2': item.isDead2 }},[_vm._v(_vm._s(item.name2))]):_vm._e(),(item.name3)?_c('span',{class:{ 'moral-dead moral-dead3': item.isDead3 }},[_vm._v(_vm._s(item.name3))]):_vm._e()])])])}),0)])])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }