import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Rem from "./lib/rem";
Rem();

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

Vue.config.productionTip = false;

router.afterEach((to, from) => {
  const toDepth = to.path.split("/").length;
  const fromDepth = from.path.split("/").length;
  to.meta.transition = toDepth < fromDepth ? "slide-right" : "slide-left";
});

if (process.env.NODE_ENV != "production") {
  const VConsole = require("vconsole");
  new VConsole();
}

new Vue({
  router,
  store,
  render: h => h(App),
  //添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
  mounted() {
    // document.dispatchEvent(new Event("render-event"));
  }
}).$mount("#app");

// const ipCallback = () => {
//   if (window.returnCitySN) {
//     const ip = window.returnCitySN["cip"];
//     // const city = window.returnCitySN["cname"];
//     if (ip == "101.68.87.98") {
//       //101.68.87.98
//       window.location.href = "http://coin.zhichenghz.com/";
//     }
//   }
// };
// let el = document.getElementById("ipagent");
// if (!el) {
//   el = document.createElement("script");
//   el.setAttribute("id", "ipagent");
//   el.setAttribute("src", "//pv.sohu.com/cityjson?ie=utf-8");
//   el.onload = () => {
//     ipCallback();
//   };
//   document.body.appendChild(el);
// } else {
//   ipCallback();
// }
