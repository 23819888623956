<template>
  <div class="home">
    <el-carousel
      :height="windowHeight + 'px'"
      :interval="5000"
      :autoplay="true"
      :loop="true"
      indicator-position="none"
    >
      <el-carousel-item v-for="moral in moralList" :key="moral.edition">
        <div class="moral">
          <div class="edition" :style="{ top: editionTop + '%' }">
            第{{ moral.edition | converToUpper }}届
          </div>
          <div class="list">
            <div
              class="firstList"
              :style="{
                'grid-template-columns':
                  'repeat(' +
                  moral.firstRowCol +
                  ',' +
                  100 / moral.firstRowCol +
                  '%)'
              }"
            >
              <div
                class="moral-item"
                v-for="item in moral.firstList"
                :key="item.index"
              >
                <div
                  class="moral-content"
                  :style="{
                    width:
                      windowWidth /
                        (moral.firstRowCol < 6 ? 6 : moral.firstRowCol + 1) +
                      'px'
                  }"
                  @click="go(moral, item)"
                >
                  <img
                    class="moral-img"
                    :src="
                      require('./imgs/moral_p_' +
                        moral.edition +
                        '_' +
                        item.index +
                        '.jpg')
                    "
                  />
                  <div
                    class="moral-name"
                    :class="{
                      'moral-fulldead': item.isFullDead,
                      'moral-full': item.isFull
                    }"
                  >
                    <span
                      :class="{
                        'moral-dead':
                          item.isDead && !item.isFullDead && !item.isFull,
                        'moral-moretext':
                          item.name.length > 10 &&
                          !item.isFullDead &&
                          !item.isFull
                      }"
                      >{{ item.name }}</span
                    >
                    <span
                      :class="{ 'moral-dead moral-dead2': item.isDead2 }"
                      v-if="item.name2"
                      >{{ item.name2 }}</span
                    >
                    <span
                      :class="{ 'moral-dead moral-dead3': item.isDead3 }"
                      v-if="item.name3"
                      >{{ item.name3 }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="secondList"
              :style="{
                'grid-template-columns':
                  'repeat(' +
                  moral.secondRowCol +
                  ',' +
                  100 / moral.secondRowCol +
                  '%)'
              }"
            >
              <div
                class="moral-item"
                v-for="item in moral.secondList"
                :key="item.index"
              >
                <div
                  class="moral-content"
                  :style="{
                    width:
                      windowWidth /
                        (moral.firstRowCol < 6 ? 6 : moral.firstRowCol + 1) +
                      'px'
                  }"
                  @click="go(moral, item)"
                >
                  <img
                    class="moral-img"
                    :src="
                      require('./imgs/moral_p_' +
                        moral.edition +
                        '_' +
                        item.index +
                        '.jpg')
                    "
                  />
                  <div
                    class="moral-name"
                    :class="{
                      'moral-fulldead': item.isFullDead,
                      'moral-full': item.isFull
                    }"
                  >
                    <span
                      :class="{
                        'moral-dead':
                          item.isDead && !item.isFullDead && !item.isFull,
                        'moral-moretext':
                          item.name.length > 10 &&
                          !item.isFullDead &&
                          !item.isFull
                      }"
                      >{{ item.name }}</span
                    >
                    <span
                      :class="{ 'moral-dead moral-dead2': item.isDead2 }"
                      v-if="item.name2"
                      >{{ item.name2 }}</span
                    >
                    <span
                      :class="{ 'moral-dead moral-dead3': item.isDead3 }"
                      v-if="item.name3"
                      >{{ item.name3 }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      windowHeight: 1440,
      windowWidth: 2560,
      editionTop: 1.15,
      moralList: [
        {
          edition: 1,
          firstRowCol: 4,
          firstList: [
            {
              index: 0,
              name: "蒋保林",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "郭关珍",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "江航",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "秦慈田",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 3,
          secondList: [
            {
              index: 4,
              name: "许月珍",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "任国栋",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "黄梦杰",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 2,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "程本",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "潘顺法",
              isDead: true,
              name2: "郑满珍",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "云南维西县四十朵花花小学支教团",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "汪霖",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "王万林",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 5,
              name: "杨铮君",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "鲍大荣",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "蒋兴权",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "杨桂英",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 3,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "孔胜耀",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "邵中国",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "周文霞",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "陈珍珠",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "丁云川",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 5,
              name: "沈天仁",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "杨志平",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "周其苗",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "周晓琼",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 4,
          firstRowCol: 4,
          firstList: [
            {
              index: 0,
              name: "姜军平",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "李月元",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "俞洋",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "申屠明",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 3,
          secondList: [
            {
              index: 4,
              name: "吴敏强",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "郑琦",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "陈亮亮",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 5,
          firstRowCol: 4,
          firstList: [
            {
              index: 0,
              name: "金晓军",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "李东华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "钱老三",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "隋瑞福",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 4,
              name: "周兆木",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "刘晓芳",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "童天佑",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "高彩珍",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 6,
          firstRowCol: 4,
          firstList: [
            {
              index: 0,
              name: "鲍倩",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "高亚莲",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "何剑兴",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "蒋金海",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 4,
              name: "罗红英",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "王雅利",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "易贤华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "章碧珍",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 7,
          firstRowCol: 4,
          firstList: [
            {
              index: 0,
              name: "冯坚强",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "蒋定军",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "王媛媛",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "吴木娟",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 3,
          secondList: [
            {
              index: 4,
              name: "吴仁贤",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "余林",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "淳安县姜家镇浮林村\r\n2·5山林火灾救火英雄群体",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false,
              isFullDead: true
            }
          ]
        },
        {
          edition: 8,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "陈辽敏",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "黄小荣",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "嵇小河",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "钱荣生",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "张大华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 5,
              name: "张思洋",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "章大春",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "郑娟美",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "周立美",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 9,
          firstRowCol: 4,
          firstList: [
            {
              index: 0,
              name: "蒋春英",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "刘朝玉",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "陆顺昌",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "欧阳光霞",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 4,
              name: "饶儒瑾",
              isDead: true,
              name2: "张竹梅",
              isDead2: true,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "虞纯",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "江建军",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "尹进良",
              isDead: true,
              name2: "陈伟",
              isDead2: true,
              name3: "尹智慧",
              isDead3: true
            }
          ]
        },
        {
          edition: 10,
          firstRowCol: 6,
          firstList: [
            {
              index: 0,
              name: "陈思晓",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "丁乐冬",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "何军",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "史君",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "许航",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "叶联声",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 5,
          secondList: [
            {
              index: 6,
              name: "叶永棠",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "章少华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "朱忠华",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 9,
              name: "祝旭东",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 10,
              name: "“7·5”公交车放火案见义勇为群体",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false,
              isFull: true
            }
          ]
        },
        {
          edition: 11,
          firstRowCol: 4,
          firstList: [
            {
              index: 0,
              name: "黄飞华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "王森章",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "吴庭槐",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "楼飞华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 4,
              name: "钱爱娟",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "施永忠",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "金长",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "临平区公安分局交警邱山二号岗",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 12,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "杨小君",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "马克·奥斯本",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "徐元华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "毛谦德",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "曹立云",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 5,
          secondList: [
            {
              index: 5,
              name: "礼为奇",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "田燕儿",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "杜佳琪",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "楼军炜",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 9,
              name: "杨全六",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 13,
          firstRowCol: 4,
          firstList: [
            {
              index: 0,
              name: "淳安县浪川乡双源完小爱心送教团队",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "半山街道杭钢南苑社区“最美”环卫工人（团队）",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false,
              isFull: true
            },
            {
              index: 2,
              name: "朗丽英",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "陈浩",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 4,
              name: "胡勇萍",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 5,
              name: "达式华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "王坤森",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "方叶平",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 14,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "5·22勇救坠楼幼儿群体",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "干丽君",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "金健勇",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "俞柏堂",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "“校门爷爷”志愿服务队",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 5,
          secondList: [
            {
              index: 5,
              name: "秦维",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "徐沙",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "徐遂",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "陶长夫",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 9,
              name: "蒋臻",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 15,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "何阿奎",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "靳毅",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "沈怀花",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "刘新停",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "王丰华",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 4,
          secondList: [
            {
              index: 5,
              name: "隋永辉",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "张方林",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "俞涤萍",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "胡小燕",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 16,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "戚宝兴",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "方平尔",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "韩伦语",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "黄小建",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "王志熊",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 5,
          secondList: [
            {
              index: 5,
              name: "汪红玉",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "陈杰",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "向达勇",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "陆群",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 9,
              name: "韦长春",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 17,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "徐川子",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "王国平",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "俞海泉",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "戴利雅",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "胡阿虎",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 5,
          secondList: [
            {
              index: 5,
              name: "庞汝勋",
              isDead: true,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "常学平",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "齐艳",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "胡松山",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 9,
              name: "丁光明",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        },
        {
          edition: 18,
          firstRowCol: 5,
          firstList: [
            {
              index: 0,
              name: "鲍有林",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 1,
              name: "麻培均",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 2,
              name: "徐洋",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 3,
              name: "陶杏娟",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 4,
              name: "陈志飞",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ],
          secondRowCol: 5,
          secondList: [
            {
              index: 5,
              name: "宣丙水",
              isDead: false,
              name2: "李彩英",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 6,
              name: "谢建华",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 7,
              name: "王勇",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 8,
              name: "解四连",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            },
            {
              index: 9,
              name: "何月仙",
              isDead: false,
              name2: "",
              isDead2: false,
              name3: "",
              isDead3: false
            }
          ]
        }
      ]
    };
  },
  filters: {
    converToUpper(val) {
      const upperNum = [
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二",
        "十三",
        "十四",
        "十五",
        "十六",
        "十七",
        "十八",
        "十九",
        "二十"
      ];
      if (val && val >= 0 && val < upperNum.length) {
        return upperNum[val];
      }
      return val;
    }
  },
  mounted() {
    this.onInitView();
    window.addEventListener("resize", this.onInitView);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onInitView);
  },
  methods: {
    go(moral, item) {
      this.$router.push({
        path: "/detail",
        query: {
          edition: moral.edition,
          index: item.index
        }
      });
    },
    onInitView() {
      const currScale = (window.innerWidth / window.innerHeight).toFixed(4);
      //console.log('width' + window.innerWidth + '-height' + window.innerHeight + '-scale ' + currScale);
      const scale = (1280 / 720).toFixed(4);
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      //console.log('scale - ' + scale + '- currscale -' + currScale);
      this.editionTop = (24.6 / (currScale / scale)).toFixed(4);
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  margin: 0;
}
.moral {
  background: url("./imgs/moral_bg.png") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: relative;
  .edition {
    top: 24.6%;
    width: 100%;
    text-align: center;
    font-size: 0.2rem;
    font-weight: bold;
    position: absolute;
    letter-spacing: 10px;
    color: #b12c22;
  }
  .list {
    position: absolute;
    top: 33%;
    width: 92%;
    left: 4%;
    height: 62%;
    .firstList {
      display: grid;
      height: 50%;
      justify-items: center;
    }
    .secondList {
      display: grid;
      height: 50%;
      justify-items: center;
    }
    .moral-item {
      height: 100%;
      overflow: hidden;
      .moral-content {
        height: 98%;
        background: url("./imgs/moral_item_bg.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
        .moral-img {
          width: 94%;
          height: 80%;
          margin-top: 5%;
          object-fit: cover;
          object-position: top;
        }
        .moral-name {
          font-size: 32px;
          font-weight: bold;
          color: #4b0a05;
          text-align: center;
          line-height: 66px;
          letter-spacing: 5px;
          .moral-moretext {
            line-height: 44px;
            font-size: 20px;
            white-space: pre-wrap;
          }
          .moral-dead {
            border: #4b0a05 solid 4px;
            padding-left: 3px;
            padding-right: 3px;
          }
          .moral-dead2 {
            margin-left: 3px;
          }
          .moral-dead3 {
            margin-left: 3px;
          }
        }
        .moral-full {
          line-height: 25px;
          font-size: 20px;
          width: 80%;
        }
        .moral-fulldead {
          line-height: 25px;
          font-size: 20px;
          border: #4b0a05 solid 4px;
          padding-left: 3px;
          padding-right: 3px;
          width: 80%;
        }
      }
    }
  }
}
</style>
